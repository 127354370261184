import LocalContext from "./LocaleContext";
import LocalizationProvider from "./LocalizationProvider";
// import LocalizedStaticQuery from "./LocalizedStaticQuery";
import useLocale from "./useLocale";

export {
  LocalContext,
  LocalizationProvider,
  // LocalizedStaticQuery,
  useLocale,
}
import React from 'react'
import styled from "@emotion/styled";
import Img from "gatsby-image";
import {
  FullHeightCover,
  Carousel,
  LogoOverlay,
  MoreButton as MoreButtonBase
} from '@shockventures/gatsby-theme-common/src/components/Cover'
import CoverLogo from "./CoverLogo";
import CoverSlide from "./CoverSlide";
import {useSanity} from "@shockventures/gatsby-theme-common/src/sanity";
import {BsChevronDoubleDown} from 'react-icons/bs';

const MoreButton = styled(MoreButtonBase)`
  bottom: 135px;
`

export default ({covers, logo, scrollTo}) => {
  const getFluidGatsbyImage = useSanity().getFluidGatsbyImage
  return (
      <div style={{marginTop: '-150px'}}>
        <FullHeightCover inset={'24px'} css={{zIndex: 2, backgroundColor: 'white'}}>
          <Carousel fade={true}>{covers.map((cover, key) => {
            const fluidProps = cover.photos && cover.photos[0] ? getFluidGatsbyImage(cover.photos[0].asset._id, {maxWidth: 1400}) : null
            return !fluidProps ? null :
                <CoverSlide key={key} cover={cover}><Img fluid={fluidProps} durationFadeIn={1000}/></CoverSlide>
          })}</Carousel>
          <LogoOverlay logo={<CoverLogo>{logo}</CoverLogo>} title={''}/>
          {scrollTo ?
              <MoreButton className={'more'} scrollTo={scrollTo} icon={<BsChevronDoubleDown/>}>More</MoreButton> : null}
        </FullHeightCover>
      </div>
  )
}

const path = require("path")
const slugify = require("slugify")
const languages = require("./data/languages")

const studioSlugify = function(studio, lang) {
  return path.join('/', lang === languages.defaultLangKey ? '' : `${lang}`).replace(/\\/g, '/')
}

const artistSlugify = function (artist, lang) {
  return path.join('/', lang === languages.defaultLangKey ? '' : `${lang}`, slugify(artist.name.replace("'", '-') + '-' + artist.tagline, {
    lower: true,
    strict: true,
    replacement: '-'
  })).replace(/\\/g, '/')
}

module.exports = {
  studioSlugify: studioSlugify,
  artistSlugify: artistSlugify
}
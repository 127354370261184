import React from "react"
import styled from "@emotion/styled"
import {graphql, StaticQuery} from "gatsby"
import Img from "gatsby-image";
import {useSanity} from "@shockventures/gatsby-theme-common";

const LogoInner = styled.div`
    position: relative;
    padding: 24px;
    ${props => props.theme.mq({
      width: ['300px', '300px', '300px', '300px'],
    })};
    background-color: white;
    img {
      width: 100%;
    }
`
export default ({children}) => {
  const getFluidGatsbyImage = useSanity().getFluidGatsbyImage
  return (
  <StaticQuery
      query={graphql`
              query {
                studio: sanityStudio(id: {eq: "-0d8257a6-9215-5cc4-9480-1254705e2e42"}) {
                    ...StudioFragment
                }
               }
          `}
      render={data => {
        console.log('CoverLogo', data)
        return (<LogoInner>
          <Img fluid={getFluidGatsbyImage(data.studio.logo.asset._id, {maxWidth: 800})}/>
        </LogoInner>)
      }}/>)
}


import {serializers} from "@shockventures/gatsby-theme-common";
import CoverSection from "./sections/CoverSection"

export default {
  blocks: {
    ...serializers.blocks
  },
  sections: {
    types: {
      ...serializers.sections.types, ...{
        coverSection: CoverSection,
      }
    }
  }
}

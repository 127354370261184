import React from 'react'
import styled from '@emotion/styled'
import Link from '../Link'
import {useSanity} from "../../sanity";
import {useLocale} from "../../locale";
import ImageSliderFlickity from "./ImageSliderFlickity";
import Img from 'gatsby-image';


const Article = styled.section`
  position: relative;
  outline: 1px solid #CCC;
  background-color: white;
  padding: 24px;
  //font-size: 1.3rem;
`
const DateRange = styled.div`
`
const Title = styled.h2`
  margin: 6px 0 4px;
  font-family: ${props => props.theme.headerFont};
  //font-size: 24px;
  font-weight: 400;
  text-align: left;
  text-transform: uppercase;
`
const SubTitles = styled.div`
  font-weight: 700;
  text-transform: uppercase;
`
const Image = styled.div`
  margin: 12px 0;
  // outline: 1px solid red;
`;
const Body = styled.div`
  margin: 12px 0;
`;
const Details = styled.div`
  margin: 12px 0;
  tr {
    vertical-align:top;
  }
  th {
    padding-right: 12px;
    text-transform: uppercase;
  }
  a, a:visited {
    display: block;
    color: ${props => props.theme.textColor};
    text-decoration: underline;
  }
`;

const offset = '0';
const bgColor = '#EFEFEF';

const BannerContainer = styled.div`
  //z-index: 1;
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute; 
  top: ${offset};
  right: ${offset};
  padding-left: 60px;
  &:after {
    content: "";
    display: block;
    width: 10px;
    height: 5px;
    background-color: darken(${bgColor}, 5%);
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
  }
  &:before {
    content: "";
    display: block;
    height: 10px;
    width: 5px;
    background-color: darken(${bgColor}, 5%);
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
`
// const Banner = styled.div`
//   width: 120px;
//   padding: 5px 30px;
//   background-color: ${bgColor};
//   color: ${props => props.theme.textColor};
//   font-family: ${props => props.theme.headerFont};
//   text-align: center;
//   // transform: rotate(-45deg) translate(-28%, -35%); // translate(-28%, -35%)
//   transform: rotate(45deg) translate(0%,30%); // translate(-28%, -35%)
//   // box-shadow: 0px 2px 2px rgba(0,0,0,0.2);
//   text-transform: uppercase;
//   // text-shadow: 0 2px 2px rgba(0,0,0,0.4);
// `
export default ({article}) => {
  // console.log('EventArticle', article)
  const getFluidGatsbyImage = useSanity().getFluidGatsbyImage;
  const locale = useLocale();
  return (
      <Article>
        <BannerContainer>
          {/*<Banner>Ausstellung</Banner>*/}
        </BannerContainer>
        <DateRange>{locale.localizeDate(article.startedAt)} - {locale.localizeDate(article.endedAt)}</DateRange>
        <Title>{article.title}</Title>
        <SubTitles>{article.subTitle}</SubTitles>
        <Image>
          <ImageSliderFlickity>
            {article.images.map((image, key) => <Img key={key}
                                                     fluid={getFluidGatsbyImage(image.asset.id, 500)}/>)}
          </ImageSliderFlickity>
        </Image>
        <Body>{article.content}</Body>
        <Details>
          <table>
            <tbody>
            <tr>
              <th>Künstler</th>
              <td>
                {article.artists ? article.artists.map((artist, key) => (
                    <Link key={key} to={artist.website.url}>{artist.name}</Link>
                )) : null}
              </td>
            </tr>
            {article.dates.map((event, key) => (
                <tr key={key}>
                  <th>{event.title}</th>
                  <td>{locale.localizeDateTime(event.startedAt)}</td>
                </tr>
            ))}
            {/*    <tr>*/}
            {/*      <th>Location</th>*/}
            {/*      <td>*/}
            {/*        {article.location.name}<br/>*/}
            {/*        {article.location.street}<br/>*/}
            {/*        {article.location.postalCode} {article.location.city}*/}
            {/*      </td>*/}
            {/*    </tr>*/}
            {article.website.url &&
            <tr>
              <th>Website</th>
              <td><Link to={article.website.url}>{article.website.title}</Link></td>
            </tr>
            }
            </tbody>
          </table>
        </Details>
      </Article>
  )
}
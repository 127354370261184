import React from "react"
import styled from "@emotion/styled"
import {graphql, Link, StaticQuery} from "gatsby"
import Img from "gatsby-image";
//
import {Container, Row, Col} from "@shockventures/gatsby-theme-common/src/components/Grid"
import Layout from "@shockventures/gatsby-theme-common/src/components/Layout"
import PageSections from "@shockventures/gatsby-theme-common/src/components/PageSections"

//
import Footer from "./Footer"
import serializers from "../serializers"
import {artistSlugify} from "../utils"
import {useLocale} from "@shockventures/gatsby-theme-common";
import {useSanity} from "@shockventures/gatsby-theme-common";

const NavBar = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 1;
    background-color: rgba(255,255,255,0.95);
    .gatsby-image-wrapper {
        display: block !important;
        margin: 8px auto 25px;
        width: 160px;
        max-width: 100%;
    }
`
const ArtistNav = styled.div`
  ${props => props.theme.mq({display: ['none', 'block']})};
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: ${props => props.theme.headerFont};
  text-transform: uppercase;
  text-align: center;
`
const Nav = styled(Link)`
  display: block;
  font-size: 2rem;
  margin: 0;
  color: ${props => props.theme.textColor};
  &:hover {
    color: ${props => props.theme.textColor};
  }
  &.active {
  }
  &.active div {
    opacity: 1;
  }
`
const Logo = styled(Img)`
  //width: 200px;
`
const SubNav = styled(Link)`
  margin: 0;
  //font-size: 0.8rem;
  color: ${props => props.theme.textColor};
  &:hover {
    color: ${props => props.theme.textColor};
  }
  &:after {
    content: '|';
    padding: 0 5px;
  }
  &:last-child:after {
    content: '';
    padding: 0;
  }
`
const Content = styled.div`
  margin-top: 150px;
`
const Artist = ({name, slug}) => (
    <ArtistNav>
      <Nav to={slug} activeClassName="active">{name}</Nav>
      <SubNav to={`${slug}#portfolio-page-section`}>Portfolio</SubNav>
      <SubNav to={`${slug}#bio-page-section`}>Bio</SubNav>
      <SubNav to={`${slug}#news-page-section`}>News</SubNav>
    </ArtistNav>
)

export default ({siteContext, data, children}) => {
  let page = data.page
  let locale = useLocale()
  const getFluidGatsbyImage = useSanity().getFluidGatsbyImage
  return (
  <StaticQuery
      query={graphql`
              query {
                logo: file(name: {eq: "logo"}) {...logoImage}
                studio: sanityStudio(id: {eq: "-0d8257a6-9215-5cc4-9480-1254705e2e42"}) {
                    ...StudioFragment
                }
                artists: allSanityArtist {
                    edges {
                        node {
                            name
                            tagline {
                                de
                                en
                            }
                        }
                    }
                }
              }
          `}
      render={data => {
        data = locale.localizeData(data)
        return (
            <Layout siteContext={siteContext} page={page}>
              <NavBar>
                <Container>
                  <Row>
                    <Col><Artist name={data.studio.artists[0].artist.name} slug={artistSlugify(data.studio.artists[0].artist, locale.locale)}/></Col>
                    <Col style={{textAlign: 'center', paddingTop: '15px'}}><Link to={'/'}><Logo fluid={getFluidGatsbyImage(data.studio.logo.asset._id, {maxWidth: 400})}/></Link></Col>
                    <Col><Artist name={data.studio.artists[1].artist.name} slug={artistSlugify(data.studio.artists[1].artist, locale.locale)}/></Col>
                  </Row>
                </Container>
              </NavBar>
              <Content>
              <PageSections siteContext={siteContext} page={page} serializers={serializers}/>
              {children}
              </Content>
              <Footer siteContext={siteContext}/>
            </Layout>
        )
      }}/>)
}
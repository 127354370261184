import React, {useRef} from "react"
import {graphql} from "gatsby"
import PageSection from "@shockventures/gatsby-theme-common/src/components/PageSection"
import Cover from '../components/Cover/Cover'

export default ({navigations, covers}) => {
  const nextSectionRef = useRef(null)
  return (
    <PageSection type={'cover'}>
      <Cover covers={covers} scrollTo={nextSectionRef}/>
      <hr className={'page-section-break'} ref={nextSectionRef}/>
    </PageSection>
  )
}

export const query = graphql`
    fragment CoverSectionFragment on SanityCoverSection {
        _key
        _type
        covers {
            _rawTitle
            artist {
                ...Artist
            }
            photos {
                asset {
                    _id
                    url
                    # Only works with gatsby-source-sanity-transform-images (which causes createPages loop)
                    #                    localFile(width: 1400) {
                    #                        publicURL
                    #                        childImageSharp {
                    #                            fluid {
                    #                                ...GatsbyImageSharpFluid
                    #                            }
                    #                        }
                    #                    }
                }
            }
        }
    }
`
module.exports = {
  "siteLayout": require("./siteLayout"),
  "connectedAccounts": [
    {
      "screenname": "Instagram",
      "profileUrl": "https://www.instagram.com/artstudio6t/",
    }
  ],
  "showResponsiveBar": false,
  "sanity": {
    "projectId": 'mppsmaef',
    "dataset": 'production',
  },
  "mailchimp": {
    "url": "https://art-studio-6t.us10.list-manage.com/subscribe/post?u=3714ece9883774f7af8cab462&amp;id=a5a3f633abz",
  },
  "languages": require("../data/languages"),
}
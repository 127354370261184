import React from "react"
import {Link} from "gatsby" //"@shockventures/gatsby-theme-common/src/components/Link"
import styled from "@emotion/styled"
import {artistSlugify} from "../../utils"
import {useLocale} from "@shockventures/gatsby-theme-common";

const Slide = styled.div`
    height: 100%; // required for the full screen slide to work
    width: 100%;
    //left: 0 !important; // Important fix for Flickity Carousel to not flicker on fade
    //background-color: pink;
`
const SliderOverlay = styled.div`
    position: absolute;
    bottom: 16px;
    color: white;
    font-family: ${props => props.theme.headerFont};
    text-transform: uppercase;
    a, a:hover, a:visited {
    display: block;
        color: white;
        text-decoration: none;
    }
`
const SlideOverlayRight = styled(SliderOverlay)`
    bottom: 16px;
    left: 25px;
    right: 24px;
    ${props => props.theme.mq({textAlign: ['center', 'right']})};
`
// const SlideOverlayLeft = styled(SliderOverlay)`
//     left: 24px;
//     font-weight: 400;
//     ${props => props.theme.mq({maxWidth: ['100px', '400px', '300px', '550px']})};
//     font-family: ${props => props.theme.bodyFont};
//     font-style: italic;
// `
const Name = styled.div`
    font-weight: 700;
`
const Title = styled.div`
    font-family: ${props => props.theme.bodyFont};
    font-size: 1rem;
    //font-style: italic;
`
const SlideContent = styled.div`
    height: 100%; // required for the full screen slide to work
    width: 100%;
`
export default ({cover, children}) => {
  let locale = useLocale()
  return (
      <Slide>
        <SlideContent>{children}</SlideContent>
        <SlideOverlayRight>
          <Link to={artistSlugify(cover.artist, locale.locale)}>
            <Name>{cover.artist.name}</Name><Title>{cover._rawTitle}</Title>
          </Link>
        </SlideOverlayRight>
      </Slide>
  )
}